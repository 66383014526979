<template>
  <b-card
    id="page--notes"
    no-body
  >
    <div>
      <sidebar-form
        :visible="showForm"
        :resource-id="resourceId"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div class="mr-1">
            <b-button
              id="create-button"
              variant="primary"
              :class="{
                'icon-disabled': $can('Create', 'Note') === false,
              }"
              @click="$can('Create', 'Note') && create()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Create New') }}</span>
            </b-button>
            <b-tooltip target="create-button">
              {{ $can('Create', 'Note') === true ? $t('Create') : $t('You dont have permission to create note') }}
            </b-tooltip>
          </div>
          <div>
            <imex-actions-dropdown
              :schema-name="Schema.CORE"
              :entity-type="Entity.NOTE"
            />
          </div>
        </div>
        <div>
          <prozess-search-input
            :value="search"
            :placeholder="$t('Search')"
            @input="handleSearch"
          />
        </div>
      </div>
      <div
        v-if="!customizeLoading"
        class="m-2"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(notes)="{ item }">
            <div class="line-clamp-1">
              {{ markupToText(item.notes) }}
            </div>
          </template>

          <!-- START: for customizable fields -->

          <template #cell()="data">
            <router-link
              v-if="isTitle(data)"
              class="line-clamp-1"
              :to="{
                name: 'note-view',
                params: {
                  id: data.item.uuidKey,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
            >
              {{ data.field.key === 'note' ? markupToText(findValue(data)) : findValue(data) }}
            </router-link>
            <span
              v-else
              class="line-clamp-1"
              :class="{ 'text-primary': data.field.key.includes('mobile') }"
            >
              {{ data.field.key === 'note' ? markupToText(findValue(data)) : findValue(data) }}
            </span>
          </template>

          <!-- END: for customizable fields -->

          <!-- START: Actions Column -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.uuidKey}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Update', 'Note') === false,
                }"
                @click="$can('Update', 'Note') ? edit(item.uuidKey) : null"
              />
              <b-tooltip
                placement="left"
                :title="$can('Update', 'Note') === true ? $t('Edit') : $t('You dont have permission to update note')"
                :target="`edit-row-${item.uuidKey}-preview-icon`"
              />
              <feather-icon
                :id="`delete-row-${item.uuidKey}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Delete', 'Note') === false,
                }"
                @click="remove(item.uuidKey, item.version)"
              />
              <b-tooltip
                placement="left"
                :title="$can('Delete', 'Note') === true ? $t('Delete') : $t('You dont have permission to remove note')"
                :target="`delete-row-${item.uuidKey}-preview-icon`"
              />
            </div>
          </template>
          <!-- END: Actions Column -->
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { markupToText } from '@/helpers/app'
import { Schema, Entity, GenericTabs } from '@/constants/app'
import SidebarForm from '@/views/pages/Notes/Form.vue'
import noteService from '@/services/note'
import listMixinFactory from '@/mixins/list'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'

export default {
  components: {
    SidebarForm,
    ImexActionsDropdown,
  },
  mixins: [
    listMixinFactory({
      routeName: 'notes',
      searchField: 'title',
      service: noteService,
      isCustomizable: true,
      listFormat: true,
      tableColumns: [],
      sort: {
        title: 'title',
      },
    }),
  ],
  provide() {
    return {
      tableFooter: this.tableFooter,
    }
  },
  data() {
    return {
      GenericTabs,
      Schema,
      Entity,
      resourceId: null,
      showForm: false,
    }
  },

  methods: {
    markupToText,

    create() {
      this.resourceId = null
      this.showForm = true
    },

    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },

    remove(id, version) {
      if (this.$can('Delete', 'Note') === false) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteResource(id, version, { versionKey: 'noteVersion' })
        }
      })
    },
  },
}
</script>
